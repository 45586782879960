var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"card-title"},[_vm._v("Araçlar")]),_c('router-link',{attrs:{"to":{ name: 'carstracking-createcar' }}},[_c('button',{staticClass:"btn btn-primary"},[_vm._v("Yeni Araç")])])],1),_c('div',{staticClass:"card-body p-0"},[_c('div',{staticClass:"firstlast-padding"},[_c('b-table',{ref:"table_car_list",staticClass:"mb-0 align-top",attrs:{"responsive":"","striped":"","small":"","hover":"","select-mode":"single","items":_vm.datasource.carList,"fields":_vm.datasource.table.fields,"tbody-tr-class":_vm.rowClass,"show-empty":""},on:{"row-selected":_vm.setTableRow},scopedSlots:_vm._u([{key:"cell(plate_code)",fn:function(data){return [_c('div',[_c('div',{staticClass:"media"},[_c('router-link',{attrs:{"to":{
                    name: 'carstracking-detailcar',
                    query: { uniq: data.item.car_uniq },
                  }}},[_c('img',{staticClass:"mr-3",staticStyle:{"width":"10rem"},attrs:{"src":_vm.getCarImage(data.item)}})]),_c('div',{staticClass:"media-body"},[_c('router-link',{attrs:{"to":{
                      name: 'carstracking-detailcar',
                      query: { uniq: data.item.car_uniq },
                    }}},[_c('div',[_vm._v(_vm._s(data.item.plate_code))])]),_c('div',[_c('span',[_vm._v(_vm._s(data.item.brand_name))]),_c('span',{staticClass:"d-block"},[_vm._v(_vm._s(data.item.model_name))])])],1)],1)])]}},{key:"cell(in_service)",fn:function(data){return [(data.value == 0)?_c('span',{staticClass:"badge badge-success"},[_vm._v("Evet")]):_c('span',{staticClass:"badge badge-danger"},[_vm._v("Hayır")])]}},{key:"cell(drive_user_id)",fn:function(data){return [(data.value > 0 && data.item.drive_user != null)?_c('div',[_vm._v(" "+_vm._s(data.item.drive_user.user_namesurname)+" ")]):_vm._e()]}},{key:"cell(insurance_date)",fn:function(data){return [(data.value!=null && data.value!='')?_c('div',[_c('div',[_vm._v(_vm._s(_vm.moment(data.value).format('DD.MM.YYYY')))]),(_vm.remainingDateCheck(data.value) < 0)?_c('div',[_c('span',{staticClass:"badge badge-danger"},[_vm._v(_vm._s(Math.abs(_vm.remainingDateCheck(data.value)))+" Gün Geçti")])]):_vm._e(),(_vm.remainingDateCheck(data.value) > 0  && _vm.remainingDateCheck(data.value) < 15 )?_c('div',[_c('span',{staticClass:"badge badge-warning"},[_vm._v(_vm._s(_vm.remainingDateCheck(data.value))+" Gün Kaldı")])]):_vm._e()]):_vm._e()]}},{key:"cell(inspection_date)",fn:function(data){return [(data.value!=null && data.value!='')?_c('div',[_c('div',[_vm._v(_vm._s(_vm.moment(data.value).format('DD.MM.YYYY')))]),(_vm.remainingDateCheck(data.value) < 0)?_c('div',[_c('span',{staticClass:"badge badge-danger"},[_vm._v(_vm._s(Math.abs(_vm.remainingDateCheck(data.value)))+" Gün Geçti")])]):_vm._e(),(_vm.remainingDateCheck(data.value) > 0  && _vm.remainingDateCheck(data.value) < 15 )?_c('div',[_c('span',{staticClass:"badge badge-warning"},[_vm._v(_vm._s(_vm.remainingDateCheck(data.value))+" Gün Kaldı")])]):_vm._e()]):_vm._e()]}},{key:"cell(detail)",fn:function(data){return [_c('router-link',{attrs:{"to":{
                name: 'carstracking-history',
                query: { uniq: data.item.car_uniq },
              }}},[_vm._v(" Detaylar ")])]}},{key:"empty",fn:function(){return [_c('div',{staticClass:"pt-3 pb-3 text-center"},[_vm._v(" Listelenecek bir kayıt bulunmuyor. ")])]},proxy:true}])})],1)]),_c('b-modal',{attrs:{"hide-footer":"","centered":"","size":"lg"},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [(_vm.carstore.car.drive_user_id == 0)?_c('span',[_vm._v("Araç Alımı")]):_c('span',[_vm._v("Araç Teslimi")]),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.carstore.car.plate_code))])]},proxy:true}]),model:{value:(_vm.datasource.showcarmodal),callback:function ($$v) {_vm.$set(_vm.datasource, "showcarmodal", $$v)},expression:"datasource.showcarmodal"}},[_c('div',{staticClass:"d-block"},[_c('div',{staticClass:"row mb-05"},[_c('div',{staticClass:"col-sm-4 text-uppercase"},[_vm._v("Marka Model")]),_c('div',{staticClass:"col-sm-8"},[_c('input',{staticClass:"form-control",attrs:{"type":"text","disabled":""},domProps:{"value":_vm.carstore.car.brand_name + ' ' + _vm.carstore.car.model_name}})])]),_c('div',{staticClass:"row mb-05"},[_c('div',{staticClass:"col-sm-4 text-uppercase"},[_vm._v("Güncel KM")]),_c('div',{staticClass:"col-sm-8"},[_c('input',{staticClass:"form-control",attrs:{"type":"text","disabled":""},domProps:{"value":_vm.carstore.car.current_km}})])]),(_vm.carstore.car.drive_user_id == 0)?_c('div',[_c('div',{staticClass:"row mb-05"},[_c('div',{staticClass:"col-sm-4 text-uppercase"},[_vm._v("Alım Tarihi")]),_c('div',{staticClass:"col-sm-8"},[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{
                  enableTime: true,
                  altFormat: 'd.m.Y H:i',
                  altInput: true,
                  dateFormat: 'Y-m-d H:i',
                  locale: 'tr',
                }},model:{value:(_vm.carstore.car_km.received_date),callback:function ($$v) {_vm.$set(_vm.carstore.car_km, "received_date", $$v)},expression:"carstore.car_km.received_date"}})],1)]),_c('div',{staticClass:"row mb-05"},[_c('div',{staticClass:"col-sm-4 text-uppercase"},[_vm._v("Teslim Alan")]),_c('div',{staticClass:"col-sm-8"},[_c('v-select',{attrs:{"options":_vm.datasource.users,"reduce":function (option) { return option.user_id; },"label":"user_namesurname","searchable":true,"clearable":false},model:{value:(_vm.carstore.car_km.user_id),callback:function ($$v) {_vm.$set(_vm.carstore.car_km, "user_id", $$v)},expression:"carstore.car_km.user_id"}})],1)]),_c('div',{staticClass:"row mb-05"},[_c('div',{staticClass:"col-sm-4 text-uppercase"},[_vm._v("Teslim Alındığı KM")]),_c('div',{staticClass:"col-sm-8"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.carstore.car_km.received_km),expression:"carstore.car_km.received_km"}],staticClass:"form-control",attrs:{"type":"number"},domProps:{"value":(_vm.carstore.car_km.received_km)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.carstore.car_km, "received_km", $event.target.value)}}})])])]):_c('div',[_c('div',{staticClass:"row mb-05"},[_c('div',{staticClass:"col-sm-4 text-uppercase"},[_vm._v("Alındığı KM")]),_c('div',{staticClass:"col-sm-8"},[_c('input',{staticClass:"form-control",attrs:{"readonly":""},domProps:{"value":_vm.carstore.car_km.received_km}})])]),_c('div',{staticClass:"row mb-05"},[_c('div',{staticClass:"col-sm-4 text-uppercase"},[_vm._v("Teslim Edildiği KM")]),_c('div',{staticClass:"col-sm-8"},[_c('input',{staticClass:"form-control",attrs:{"type":"number"},domProps:{"value":_vm.carstore.car_km.delivered_km}})])]),_c('div',{staticClass:"row mb-05"},[_c('div',{staticClass:"col-sm-4 text-uppercase"},[_vm._v("Teslim Edildiği Tarih")]),_c('div',{staticClass:"col-sm-8"},[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{
                  enableTime: true,
                  altFormat: 'd.m.Y H:i',
                  altInput: true,
                  dateFormat: 'Y-m-d H:i',
                  locale: 'tr',
                }},model:{value:(_vm.carstore.car_km.delivered_date),callback:function ($$v) {_vm.$set(_vm.carstore.car_km, "delivered_date", $$v)},expression:"carstore.car_km.delivered_date"}})],1)])]),_c('div',{staticClass:"form-group"},[_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){return _vm.saveCarKMItem()}}},[_vm._v(" Kaydet ")])])])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }