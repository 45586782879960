<template>
  <div>
    <div class="card">
      <div class="card-header">
        <div class="card-title">Araçlar</div>
        <router-link :to="{ name: 'carstracking-createcar' }">
          <button class="btn btn-primary">Yeni Araç</button>
        </router-link>
      </div>
      <div class="card-body p-0">
        <div class="firstlast-padding">
          <b-table
            ref="table_car_list"
            responsive
            striped
            small
            hover
            select-mode="single"
            @row-selected="setTableRow"
            :items="datasource.carList"
            :fields="datasource.table.fields"
            :tbody-tr-class="rowClass"
            class="mb-0 align-top"
            show-empty
          >
            <template #cell(plate_code)="data">
              <div>
                <div class="media">
                  <router-link
                    :to="{
                      name: 'carstracking-detailcar',
                      query: { uniq: data.item.car_uniq },
                    }"
                  >
                    <img
                      class="mr-3"
                      :src="getCarImage(data.item)"
                      style="width: 10rem"
                    />
                  </router-link>
                  <div class="media-body">
                    <router-link
                      :to="{
                        name: 'carstracking-detailcar',
                        query: { uniq: data.item.car_uniq },
                      }"
                    >
                      <div>{{ data.item.plate_code }}</div>
                    </router-link>
                    <div>
                      <span>{{ data.item.brand_name }}</span>
                      <span class="d-block">{{ data.item.model_name }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template #cell(in_service)="data">
              <span v-if="data.value == 0" class="badge badge-success"
                >Evet</span
              >
              <span v-else class="badge badge-danger">Hayır</span>
            </template>
            <template #cell(drive_user_id)="data">
              <div v-if="data.value > 0 && data.item.drive_user != null">
                {{ data.item.drive_user.user_namesurname }}
              </div>
            </template>
            <template #cell(insurance_date)="data">
              <div v-if="data.value!=null && data.value!=''">
                <div>{{ moment(data.value).format('DD.MM.YYYY') }}</div>
                <div v-if="remainingDateCheck(data.value) < 0">
                  <span class="badge badge-danger">{{ Math.abs(remainingDateCheck(data.value)) }} Gün Geçti</span>
                </div>
               <div v-if="remainingDateCheck(data.value) > 0  && remainingDateCheck(data.value) < 15 ">
                  <span class="badge badge-warning">{{ remainingDateCheck(data.value) }} Gün Kaldı</span>
                </div>
              </div>
            </template>
           <template #cell(inspection_date)="data">
              <div v-if="data.value!=null && data.value!=''">
                <div>{{ moment(data.value).format('DD.MM.YYYY') }}</div>
                <div v-if="remainingDateCheck(data.value) < 0">
                  <span class="badge badge-danger">{{ Math.abs(remainingDateCheck(data.value)) }} Gün Geçti</span>
                </div>
               <div v-if="remainingDateCheck(data.value) > 0  && remainingDateCheck(data.value) < 15 ">
                  <span class="badge badge-warning">{{ remainingDateCheck(data.value) }} Gün Kaldı</span>
                </div>
              </div>
            </template>
            <template #cell(detail)="data">
              <router-link
                :to="{
                  name: 'carstracking-history',
                  query: { uniq: data.item.car_uniq },
                }"
              >
                Detaylar
              </router-link>
            </template>
            <template #empty>
              <div class="pt-3 pb-3 text-center">
                Listelenecek bir kayıt bulunmuyor.
              </div>
            </template>
          </b-table>
        </div>
      </div>
      <b-modal v-model="datasource.showcarmodal" hide-footer centered size="lg">
        <template #modal-title>
          <span v-if="carstore.car.drive_user_id == 0">Araç Alımı</span>
          <span v-else>Araç Teslimi</span>
          <span class="ml-1">{{ carstore.car.plate_code }}</span>
        </template>
        <div class="d-block">
          <div class="row mb-05">
            <div class="col-sm-4 text-uppercase">Marka Model</div>
            <div class="col-sm-8">
              <input
                type="text"
                class="form-control"
                :value="carstore.car.brand_name + ' ' + carstore.car.model_name"
                disabled
              />
            </div>
          </div>
          <div class="row mb-05">
            <div class="col-sm-4 text-uppercase">Güncel KM</div>
            <div class="col-sm-8">
              <input
                type="text"
                class="form-control"
                :value="carstore.car.current_km"
                disabled
              />
            </div>
          </div>
          <div v-if="carstore.car.drive_user_id == 0">
            <div class="row mb-05">
              <div class="col-sm-4 text-uppercase">Alım Tarihi</div>
              <div class="col-sm-8">
                <flat-pickr
                  class="form-control"
                  v-model="carstore.car_km.received_date"
                  :config="{
                    enableTime: true,
                    altFormat: 'd.m.Y H:i',
                    altInput: true,
                    dateFormat: 'Y-m-d H:i',
                    locale: 'tr',
                  }"
                />
              </div>
            </div>
            <div class="row mb-05">
              <div class="col-sm-4 text-uppercase">Teslim Alan</div>
              <div class="col-sm-8">
                <v-select
                  v-model="carstore.car_km.user_id"
                  :options="datasource.users"
                  :reduce="(option) => option.user_id"
                  label="user_namesurname"
                  :searchable="true"
                  :clearable="false"
                />
              </div>
            </div>
            <div class="row mb-05">
              <div class="col-sm-4 text-uppercase">Teslim Alındığı KM</div>
              <div class="col-sm-8">
                <input
                  type="number"
                  class="form-control"
                  v-model="carstore.car_km.received_km"
                />
              </div>
            </div>
          </div>
          <div v-else>
            <div class="row mb-05">
              <div class="col-sm-4 text-uppercase">Alındığı KM</div>
              <div class="col-sm-8">
                <input
                  class="form-control"
                  :value="carstore.car_km.received_km"
                  readonly
                />
              </div>
            </div>

            <div class="row mb-05">
              <div class="col-sm-4 text-uppercase">Teslim Edildiği KM</div>
              <div class="col-sm-8">
                <input
                  type="number"
                  class="form-control"
                  :value="carstore.car_km.delivered_km"
                />
              </div>
            </div>

            <div class="row mb-05">
              <div class="col-sm-4 text-uppercase">Teslim Edildiği Tarih</div>
              <div class="col-sm-8">
                <flat-pickr
                  class="form-control"
                  v-model="carstore.car_km.delivered_date"
                  :config="{
                    enableTime: true,
                    altFormat: 'd.m.Y H:i',
                    altInput: true,
                    dateFormat: 'Y-m-d H:i',
                    locale: 'tr',
                  }"
                />
              </div>
            </div>
          </div>
          <div class="form-group">
            <button @click="saveCarKMItem()" class="btn btn-primary">
              Kaydet
            </button>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import StatusBadge from "@/views/common/StatusBadge.vue";
import { VueAutosuggest } from "vue-autosuggest";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { tr } from "flatpickr/dist/l10n/tr.js";
import api from "@/service/api";
import utils from "@/service/utils";
import _ from "lodash";
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import moment from "moment";

export default {
  components: {
    StatusBadge,
    VueAutosuggest,
    vSelect,
    flatPickr,
  },
  data() {
    return {
      datasource: {
        carList: [],
        showcarmodal: false,
        table: {
          fields: [
            {
              key: "plate_code",
              label: "Plaka",
              sortable: true,
              selected: true,
            },
            {
              key: "in_service",
              label: "Boşta Mı?",
              sortable: true,
              selected: true,
            },
            {
              key: "drive_user_id",
              label: "Aktif Sürücü",
              sortable: true,
              selected: true,
            },
            {
              key: "current_km",
              label: "Güncel KM",
              sortable: true,
              selected: true,
            },
            {
              key: "insurance_date",
              label: "Sigorta Bitiş",
              sortable: true,
              selected: true,
            },
            {
              key: "inspection_date",
              label: "Muayene Bitiş",
              sortable: true,
              selected: true,
            },
            {
              key: "detail",
              label: "",
            },
          ],
          selected: null,
        },
      },
    };
  },
  methods: {
    ...mapActions(["saveCarKM"]),
    getData() {
      let postData = this.datasource.filter;
      api.action("getCars", { ...postData }).then((q) => {
        if (!q.success) {
          utils.notificationToast("warning", "Bilgi", q.message);
          return;
        }
        this.datasource.carList = q.data;
      });
    },
    getUsers() {
      api.action("getUsers").then((q) => {
        if (q.success) {
          this.datasource.users = q.data;
        }
      });
    },
    saveCarKMItem() {
      this.saveCarKM().then((q) => {
        if (q.success) {
          this.getData();
          this.datasource.showcarmodal = !this.datasource.showcarmodal;
        }
      });
    },
    toggleCarStateModal(v) {
      this.carstore.car = v;
      this.datasource.showcarmodal = !this.datasource.showcarmodal;
    },
    setTableRow(v) {},
    rowClass(item, type) {
      if (!item || type !== "row") {
        return;
      }
    },
    moment(v) {
      return moment(v);
    },
    remainingDateCheck(date){
      let result = '';
      if(date!=null && date!='')
      {
        let d = moment(date,'YYYY-MM-DD');
        let diff = d.diff(moment(),'days')
        result = diff;
      }
      return result;
    },
    getCarImage(item)
    {
      return require('../../assets/images/'+item.car_image);
    }
  },
  created() {
    this.getData();
    this.getUsers();
  },
  computed: {
    ...mapState({
      sf: (state) => state.sf,
      global: (state) => state.global,
      carstore: (state) => state.car,
    }),
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
